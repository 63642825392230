import React, { useEffect } from 'react'
import '../styles/projects.css';
import { popularProducts } from '../projectData.js';
import ProjectThumbnail from '../components/ProjectThumbnail.jsx';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4';

function Projects() {

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Project List" });
    }, []);

    return (
        <div className='projects-container flex fade'>
            <div>
                <h2 className='d-block numbered-title text-dark'>
                    <span aria-hidden='true'>/</span>Projects
                </h2>

                <span className=' text-accent-4 ff-serif fs-450 blur-container '>
                    Here are some projects I've worked on. Check out live demos at <a
                        className='text-accent-4'
                        href='https://projects.elenadiaz.space'
                        target='_blank'
                        rel='noreferrer'>projects.elenadiaz.space</a> <br />
                    Interested in collaborating? feel free to connect with me!

                </span>

            </div>

            <div className='flex f-center-vertical'>

                <h2 className='proj-title d-block fs-500 ff-serif text-dark letter-spacing-3'>
                    Development
                </h2>
                <div className='flex proj-content'>
                    {popularProducts
                        .filter((product) => product.type === 'react')
                        .map((item) => (
                            <Link
                                className='pr-link pt-container flex fade'
                                key={item.id}
                                to={`/Projects/${item.id}`}>
                                <ProjectThumbnail
                                    item={item}
                                    key={item.id}
                                />
                            </Link>
                        ))}
                </div>

                <h2 className='proj-title d-block fs-500 ff-serif text-dark letter-spacing-3'>
                    UX Design
                </h2>
                <div className='flex proj-content '>
                    {popularProducts
                        .filter((product) => product.type === 'ux')
                        .map((item) => (
                            <Link
                                className='pr-link pt-container flex fade'
                                key={item.id}
                                to={`/Projects/${item.id}`}>
                                <ProjectThumbnail
                                    item={item}
                                    key={item.id}
                                />
                            </Link>
                        ))}
                </div>

            </div>
        </div>
    )
}

export default Projects