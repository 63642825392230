import React from 'react';
import { useState, useEffect } from 'react';
import '../styles/main.css';
import '../styles/about.css';
import data from '../data.json';
import ReactGA from 'react-ga4';
import Publications from '../components/About Components/Publications';
import Hello from '../components/About Components/Hello';
import Experience from '../components/About Components/Experience';
import Skills from '../components/About Components/Skills';

const AboutSite = () => {
	const [currentTab, setCurrentTab] = useState('1');
	const [articles, setArticles] = useState([]);

	async function getLatestArticles() {
		const response = await fetch("https://dev.to/api/articles?username=elenadiaz32505")
			.then(result => result.json())
			.then(data => data);
		setArticles(response.reverse());
	}

	useEffect(() => {
		getLatestArticles();
		ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: `About: ${currentTab}` });
	}, []);

	const handleTabClick = (e) => {
		setCurrentTab(e.target.id);
		ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: `About: ${e.target.id}` });
	};

	return (
		<div
			id='about'
			className='about grid-container grid-container--about flow'>
			<h2 className='numbered-title text-dark blur-container'>
				<span aria-hidden='true'>/</span>About Elena
			</h2>

			{
				data.destinations.map((tab, i) => (
					<div
						key={i}
						className='about-img'>

						{currentTab === `${tab.id}` && (

							<img
								className='fade'
								src={require(`../images/${tab.images.png}`)}
								alt={tab.title}
							/>



						)}
					</div>
				))
			}

			<div className='tab-list underline-indicators flex fade blur-container'>
				{data.destinations.map((tab, i) => (
					<button
						key={i}
						id={tab.id}
						aria-pressed={currentTab === `${tab.id}` ? 'true' : 'false'}
						className='aboutbutton uppercase text-dark letter-spacing-2 ff-sans-cond fs-400'
						onClick={handleTabClick}>
						{tab.title}
					</button>
				))}
			</div>

			{
				currentTab === '1' ?
					<Hello />
					:
					currentTab === '2' ?

						<Experience />
						:
						currentTab === '3' ?
							<Skills />
							:
							<Publications articles={articles} />
			}

		</div >
	);
};

export default AboutSite;
