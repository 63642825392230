import React, { useEffect, useState } from 'react';
import '../styles/home.css';
import mobile_light from '../images/shared/mobile_light.svg';
import desktop_light from '../images/shared/desktop_light.svg';
import pinkCloud from '../images/bg/home/9.png'
import ReactGA from 'react-ga4';

const Home = () => {

	useEffect(() => {
		ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Home" });
	}, []);

	const [windowWidth, setWindowWidth] = useState(window.innerWidth);

	const imageUrl = windowWidth >= 650 ? desktop_light : mobile_light;

	useEffect(() => {
		const handleWindowResize = () => {
			setWindowWidth(window.innerWidth);
		}

		window.addEventListener('resize', handleWindowResize);
		return () => {
			window.removeEventListener('resize', handleWindowResize);
		}
	}, []);
	return (
		<div
			className='home-intro fade'>

			<div className='title '>
				<h1 className='ff-sans-cond uppercase letter-spacing-1 fs-800 bold'>Elena Diaz</h1>
				<h2 className='ff-serif letter-spacing-3 fs-700'>Frontend Engineer</h2>
			</div>

			<img
				src={imageUrl}
				alt='Elena Diaz Intro'
				className='intro-img fade'
			/>


			<img
				src={pinkCloud}
				alt='Elena Diaz Intro'
				className='pinkCloud fade'
			/>


		</div>
	);
};

export default Home;
