import React from 'react'
import { Link } from 'react-router-dom';
import '../styles/navigation.css';

function Navigation({ page, handlePageClick }) {
    function toggleMenu() {
        //Open Menu
        const nav = document.querySelector('.primary-navigation');
        const navToggle = document.querySelector('.mobile-nav-toggle');


        const visibility = nav.getAttribute('data-visible');

        if (visibility === 'false') {
            nav.setAttribute('data-visible', true);
            navToggle.setAttribute('aria-expanded', true);
        } else {
            nav.setAttribute('data-visible', false);
            navToggle.setAttribute('aria-expanded', false);
        }

    }

    return (
        <nav>

            <button
                onClick={toggleMenu}
                className='mobile-nav-toggle'
                aria-controls='primary-navigation'>
                <span
                    className='sr-only'
                    aria-expanded='false'>
                    Menu
                </span>
            </button>

            <ul
                id='primary-navigation'
                data-visible='false'
                className='primary-navigation underline-indicators flex ff-serif bold '>
                <li
                    className={page === '' ? 'active' : ''}
                    key='home'>
                    <Link
                        to='/'
                        className='uppercase text-dark fs-300 letter-spacing-2 '
                        onClick={() => handlePageClick}
                        id='home'>
                        Home
                    </Link>
                </li>
                <li
                    className={page === 'projects' ? 'active' : ''}
                    key='projects'>
                    <Link
                        className='uppercase text-dark fs-300 letter-spacing-2 '
                        to='/projects'
                        onClick={handlePageClick}
                        id='projects'>
                        Projects
                    </Link>
                </li>
                <li
                    className={page === 'about' ? 'active' : ''}
                    key='about'>
                    <Link
                        className='uppercase text-dark fs-300 letter-spacing-2 '
                        to='/about'
                        onClick={handlePageClick}
                        id='about'>
                        About
                    </Link>
                </li>
                <li
                    className={page === 'contact' ? 'active' : ''}
                    key='contact'>
                    <Link
                        className='uppercase text-dark fs-300 letter-spacing-2 '
                        to='/contact'
                        onClick={handlePageClick}
                        id='contact'>
                        Contact
                    </Link>
                </li>
            </ul>
        </nav>
    )
}

export default Navigation