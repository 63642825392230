import React from 'react'
import '../styles/footer.css';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';
import TwitterIcon from '@mui/icons-material/Twitter';
import LogoDevIcon from '@mui/icons-material/LogoDev';

function Footer() {
    const cyear = new Date().getFullYear();
    return (
        <footer className='footer'>
            <div className='flex f-center-vertical'>
                <h1 className='text-accent-2 fs-500 ff-sans-cond uppercase letter-spacing-1'>
                    Elena Diaz
                </h1>
                <p className='text-dark ff-sans-normal uppercase bold fs-300  letter-spacing-3'>
                    Let's Connect
                </p>
                <div className='flex f-center-horizontal'>


                    <a
                        href='https://www.linkedin.com/in/elenadiazse/'
                        target='_blank'
                        rel='noreferrer'
                        className='icon-link'>
                        <span className='sr-only'>LinkedIn</span>
                        <LinkedInIcon fontSize='large' />
                    </a>
                    <a
                        href='https://dev.to/elenadiaz32505'
                        target='_blank'
                        rel='noreferrer'
                        className='icon-link'>
                        <span className='sr-only'>DEV.TO</span>
                        <LogoDevIcon fontSize='large' />
                    </a>

                    <a
                        href='https://github.com/diazelena325'
                        target='_blank'
                        rel='noreferrer'
                        className='icon-link'>
                        <span className='sr-only'>Github</span>
                        <GitHubIcon fontSize='large' />
                    </a>
                    <a
                        href='https://www.instagram.com/diazelena325'
                        target='_blank'
                        rel='noreferrer'
                        className='icon-link'>
                        <span className='sr-only'>Instagram</span>
                        <InstagramIcon fontSize='large' />
                    </a>
                    <a
                        href='https://twitter.com/ElenaDiaz32505'
                        target='_blank'
                        rel='noreferrer' className='icon-link'>
                        <span className='sr-only'>Twitter</span>
                        <TwitterIcon fontSize='large' />
                    </a>
                </div>
                <div className='text-accent-5 fs-200 flex'>&copy; {cyear} Elena Diaz</div>
            </div>


        </footer>
    )
}

export default Footer