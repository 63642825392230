import React from 'react'
import data from '../../data.json';

function Experience() {
    return (
        <article className='about-info'>
            <div
                className='exp-info fade blur-container'
            >
                <div className='exptitle'><h2 className='d-block fs-500 ff-serif text-accent-4'>
                    Professional
                </h2> </div>
                {data.work.map((item, i) => (
                    <div key={i}>
                        <h2 className=' fs-400 ff-serif text-accent-4 bold'>
                            {item.role}
                        </h2>
                        <a className='text-dark'
                            target='_blank'
                            rel='noreferrer'
                            href={item.url}>
                            <p className='text-dark ff-sans-normal fs-300 bold'>
                                {item.company}
                            </p>
                        </a>
                        <p className='text-dark ff-sans-normal fs-300'>
                            {item.dates}
                        </p>

                    </div>
                ))
                }

            </div>

            <div
                className='exp-info fade blur-container'
            >
                <div className='exptitle'><h2 className='d-block fs-500 ff-serif text-accent-5'>
                    Education & Training
                </h2> </div>
                {data.education.map((item, i) => (
                    <div key={i}>
                        <h2 className='fs-400 ff-serif text-accent-5 bold'>
                            {item.name}
                        </h2>
                        <a className='text-dark'
                            target='_blank'
                            rel='noreferrer'
                            href={item.url}>
                            <p className='text-dark ff-sans-normal fs-300 bold'>
                                {item.from} - {item.dates}
                            </p>
                        </a>
                        <p className='text-dark ff-sans-normal fs-300'>
                            {item.description}
                        </p>


                    </div>
                ))
                }

            </div>
        </article>
    )
}

export default Experience