import React from 'react'
import FavoriteIcon from '@mui/icons-material/Favorite';
import '../../styles/aboutComponents/publications.css';

function Publications(props) {
    return (
        <article className='about-info'>
            <div className='pub-main-title'><h2 className=' fs-500 ff-serif text-accent-5'>
                Latest Publications
            </h2>
                <p className='bold'>Articles I've written on Dev.to covering insights on Frontend Development and Women in Tech.</p>
            </div>
            <div
                className=' fade blur-container'
            >

                {props.articles.map((item, i) => (
                    <div key={i} className='p-div '>
                        {/* <a
                        href={item.url}
                        target='_blank'
                        rel='noreferrer' className='icon-link'>
                        <span className='sr-only'>Link:{item.title}</span>
                        <OpenInNewIcon fontSize='medium' />
                    </a> */}


                        <h2 className=' fs-400 text-dark bold p-title'>
                            <a
                                href={item.url}
                                target='_blank'
                                rel='noreferrer' className='p-link'>
                                {item.title}
                            </a>
                        </h2>

                        <div className=' p-likes'>
                            <FavoriteIcon fontSize='medium' className='p-likes-icon text-accent-3' />
                            <p className='p-likes-text text-accent-3'>{item.positive_reactions_count}</p> </div>


                    </div>
                ))
                }

            </div>
        </article>
    )
}

export default Publications