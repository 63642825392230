import React from 'react'
import '../styles/projectthumbnail.css';

function ProjectThumbnail({ item }) {
    return (
        <div className='pt-div'>

            <div className='img-div'>
                <img className='pt-img' src={item.img} alt={item.text} />
            </div>

            <div className='pt-info'>
                <h2 className='fs-450 ff-serif text-dark'>{item.text}</h2>
                <p className='fs-300 text-dark'>{item.subtext}</p>
                <div >
                    {item.techstack.map((skill, i) => (
                        <button key={i} className='pt-button fs-200 '>{skill}</button>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default ProjectThumbnail