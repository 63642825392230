import React, { useState, useEffect } from 'react';
import '../styles/contact.css';
import contactImage from '../images/shared/design/mail_light.svg';
import emailSentImage from '../images/shared/design/mailsent_light.svg'
import errorImage from '../images/shared/design/error_light.svg'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import ReactGA from 'react-ga4';

const Contact = () => {
	useEffect(() => {
		ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: `Contact` });
	}, []);

	//Popup
	const overlay = document.getElementById("overlay");
	const popup = document.getElementById("result-popup");

	// Close Popup Event
	function closePopupWindow() {
		overlay.style.display = 'none';
		popup.style.display = 'none';
	}


	//Email form
	const [emailForm, setEmailForm] = useState({
		name: '',
		email: '',
		message: '',
	});
	//Result of message
	const [result, setResult] = useState('');
	const [resultImage, setResultImage] = useState('');

	//Status of sending message
	const [status, setStatus] = useState('Submit');

	function resetEmailForm() {
		setEmailForm({ name: '', email: '', message: '' });
	}

	function handleEmailFormChange(event) {
		setEmailForm((prevEmailData) => {
			return {
				...prevEmailData,
				[event.target.name]: event.target.value,
			};
		});

		if (result.length > 0) {
			setResult('');
			setResultImage(null)
		}
	}

	const handleSubmit = async (e) => {
		setResult('');
		setResultImage('');
		e.preventDefault();
		setStatus('Sending...');
		const fetchEmail = 'https://elenadiaz.space:5000/send';

		const { name, email, message } = e.target.elements;

		let details = {
			name: name.value,
			email: email.value,
			message: message.value,
		};

		try {
			let response = await fetch(fetchEmail, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json;charset=utf-8',
				},
				body: JSON.stringify(details),
			});
			setStatus('Submit');
			let result = await response.json();

			if (result.status === 'success') {
				setResult(
					'Message Sent! Will get back to you as soon as possible. Please check your Spam folder!'
				);
				setResultImage(emailSentImage);
				resetEmailForm();
			} else if (result.status === 'fail') {
				alert('Uh oh! Message failed to send.');
				setResultImage(errorImage);
			}
		} catch (error) {
			console.error(error);
			setStatus('Submit');
			setResult(
				'Uh oh! Issues with submitting message. Please feel free to reach out through social media instead. Thank you for your understanding.'
			);
			setResultImage(errorImage);
		}
		overlay.style.display = 'block';
		popup.style.display = 'flex';
	};



	return (
		<div className='contact-container flex '>



			<div className='contact-content flex fade'>

				<h2 className='d-block numbered-title text-dark blur-container'>
					<span aria-hidden='true'>/</span>Contact Me
				</h2>

				<span className='contact-span text-accent-4 ff-serif fs-450 blur-container'>
					Whether you have a project in mind, a question to ask, or just want to
					connect, I'd love to hear from you. Feel free to reach out and I'll get
					back to you as soon as I can.
				</span>
				<form
					id='contact-form'
					className='contact-form'
					onSubmit={handleSubmit}
					method='POST'
					autoComplete='on' >
					<span className='ff-serif fs-400 text-accent-4 bold '>
						Name:
					</span>
					<input
						className='contact-blur-container ff-serif fs-400'
						type='text'
						name='name'
						required={true}
						value={emailForm.name}
						onChange={handleEmailFormChange}
						autoComplete='on'
					/>
					<span className='text-accent ff-serif fs-400 text-accent-4 bold '>
						Email Address:
					</span>
					<input
						className='contact-blur-container ff-serif fs-400'
						type='email'
						name='email'
						required={true}
						value={emailForm.email}
						onChange={handleEmailFormChange}
						autoComplete='on'
					/>
					<span className='text-accent ff-serif fs-400 text-accent-4 bold '>
						Message:
					</span>
					<textarea
						className='contact-blur-container ff-serif fs-400'
						maxLength={600}
						name='message'
						required={true}
						value={emailForm.message}
						onChange={handleEmailFormChange}
						autoComplete='off'
					/>
					<button type='submit'>{status}</button>



				</form>


			</div>

			<div className='contact-content flex f-center-vertical fade'>
				<img
					src={contactImage}
					alt='contact me'
					className='contact-img fade'
				/>

			</div>

			<div id="overlay"></div>
			<div id="result-popup" className='result-popup fade'>
				<div className="popupcontrols">

					<button onClick={closePopupWindow} id="popupCloseButton" className='popupCloseButton'><CancelOutlinedIcon /></button>
				</div>

				<div className='result-content'>
					{resultImage ? <img
						src={resultImage}
						alt='result'
						className='result-img fade'
					/> : <></>}
					<h3 className='result-span text-dark ff-serif fs-450 blur-container fade'>
						{result}
					</h3>
				</div>

			</div>


		</div>
	);
};

export default Contact;
