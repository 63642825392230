import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import errorImage from '../images/shared/design/fixbutterfly_light.svg'
import ReactGA from 'react-ga4';

const Error = () => {

	useEffect(() => {
		ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: `Error` });
	}, []);

	return (
		<section className="error-div flex f-center-vertical min-height">
			<h2 className="d-block fs-800 ff-serif uppercase text-dark ">OOPS!</h2>

			<img src={errorImage} alt="error" />
			<span className="text-accent-4 ff-sans-normal fs-500">
				404 - Page Not Found
			</span>

			<div>
				<Link
					to="/"
					className="error-button ff-sans-normal uppercase f-center-vertical"
				>
					Go Home
				</Link>
			</div>
		</section>
	);
};

export default Error;
