import ron from './images/proj/ronQuote.png';
import slider from './images/proj/slider.png';
import ecommerce from './images/proj/ecommerce.png';
import mySite from './images/proj/mySite.png';
import restaurant from './images/proj/restaurant.png';
import homeRental from './images/proj/homerental.png';
import healthyMeals from './images/proj/healthymeals.png';
import shoplist from './images/proj/Shopping-List.png';
import movielist from './images/proj/Movie_List.png';
import hackernews from './images/proj/hackernews.png';
import elenafacts from './images/proj/elenafacts.png';
import squareapi from './images/proj/squareapi.png';
import contactform from './images/proj/contactform.png';
import rnm from './images/proj/rnm.png';
import pfe from './images/proj/pfe.png';
import twh from './images/proj/twh.png';
import twhdesign from './images/proj/twh_design.png';

export const popularProducts = [
    {
        id: '18',
        type: 'react',
        img: twh,
        text: 'Teddy Was Here - Art Studio',
        techstack: ['React', 'JavaScript', 'HTML', 'CSS', 'Material UI', 'Node.js', 'Express', 'Github'],
        subtext:
            'Rebranding and Website showcasing services and contact form',
        desc: "'Teddy Was Here' is an art studio website that embodies creativity and professionalism, showcasing the studio's revamped branding and commitment to elevating client engagement. Developed using React, JavaScript, HTML, and CSS, the site offers a dynamic online presence that captivates visitors from the moment they arrive. Every detail, from the frontend services showcase to the backend infrastructure, has been meticulously crafted to ensure a seamless and enjoyable user experience.",
        achievements:
            "I used React, JavaScript, and CSS to make the website interactive and fun, like adding playful animations that look like paint dripping. For the contact form, I made sure it worked smoothly using Node.js, Express, Nodemailer, and CORS, so visitors could easily reach the studio. I organized the website in a smart way using JSON data, making it easy for the studio to update their content whenever they need to. I also set up the website domain and hosting, using tools like Figma for design and GitHub for keeping everything organized. To help more people find the website, I added Google Analytics and made sure it shows up well in search engines. And of course, the website works great on all kinds of devices and follows accessibility guidelines so everyone can use it.",
        challenges:
            'Throughout this project, I encountered and conquered various challenges, particularly in mastering SEO techniques and harnessing the power of Google Analytics. However, these hurdles served as opportunities for growth, pushing me to continually refine my skills and stay abreast of industry best practices. My dedication to overcoming these challenges underscores my commitment to delivering exceptional results and ensuring the success of future projects.',
        slideLink: '',
        linklist: {
            'Visit Teddy Was Here': 'https://teddywashere.art/',
        },

        vid: '',
        cstyle: 'y',
        colors: ['#000000', '#5E376D', '#6B5A72', '#D4BBDD', '#FFCC00', '#EDE1DA', '#F4F0EC'],
        extra_imgs: [twhdesign],
    },
    {
        id: '17',
        type: 'react',
        img: pfe,
        text: 'Figma React Integration',
        techstack: ['React', 'Typescript', 'CSS', 'Styled Components'],
        subtext:
            'Frontend implementation of Figma design',
        desc: "This project involves the frontend implementation of a Figma design, showcasing the integration of a provided mockup into interactive React components using TypeScript, styled components, and vanilla CSS. The application's text and data are sourced from a local JSON file. ",
        achievements:
            'The application meets requirements for full responsiveness, modular and reusable components, and incorporates animations and transitions for an enhanced user experience.',
        challenges:
            'Areas for improvement include implementing ARIA accessibility, conducting unit testing using Jest and React Testing Library, considering a choice between full styled-components or vanilla CSS for better organization, and adding additional comments for improved code understanding.',
        slideLink: '',
        linklist: {
            'Demo': 'https://pafinfe-ediaz.netlify.app/',
            'Github': 'https://github.com/diazelena325/pafin_frontend',
        },

        vid: '',
        cstyle: 'y',
        colors: ['#2e3f51', '#5f799c', '#1e54b7', '#276ef1', '#38c97c', '#eef4fa', '#ffffff'],
    },
    {
        id: '16',
        type: 'react',
        img: rnm,
        text: 'Rick and Morty',
        techstack: ['Next.js', 'React', 'Typescript', 'CSS', 'REST API'],
        subtext:
            'Character Image Feed per episode',
        desc: 'The Rick and Morty Characters Image Feed is a dynamic Next.js application crafted with React, TypeScript, and CSS. It leverages the public API from Rick and Morty to create an engaging user experience centered around displaying character images in an intuitive feed.',
        achievements:
            '',
        challenges:
            '',
        slideLink: '',
        linklist: {
            'Demo': 'https://rickandmorty-diaz-demo.netlify.app/',
            'Github': 'https://github.com/diazelena325/rick_morty',
        },

        vid: '',
        cstyle: 'y',
        colors: ['rgb(0, 0, 0)', 'rgb(32, 55, 69)', 'rgb(191, 222, 66)', 'rgb(65, 180, 201)', 'rgb(168, 205, 205)', 'rgb(255, 255, 255)'],
    },
    {
        id: '15',
        type: 'react',
        img: contactform,
        text: 'Full-Stack Contact Form',
        techstack: ['React', 'Node', 'Express', 'Nodemailer', 'REST API'],
        subtext:
            'Tutorial creating contact form that covers frontend, backend, and deployment',
        desc: 'While working on a contact form for my own website, I encountered the frustration of having to search across various resources to piece together the entire component. To help others avoid this, I took the initiative to compose a thorough three-part tutorial series on dev.to. This series provides step-by-step guidance for developers, offering a complete roadmap to create a full stack contact form. The tutorials encompass everything from setting up domain email integration and crafting an intuitive React frontend to constructing a sturdy Node.js backend and ensuring smooth deployment.',
        achievements:
            'Through this project, I gained a deep understanding of the mechanics involved in creating a full stack component. Writing the tutorial helped me develop the skill of breaking down complex processes into clear, step-by-step instructions. This newfound ability will prove invaluable for future development projects, as I can refer back to this detailed tutorial for guidance.',
        challenges:
            'While creating the tutorial, I encountered several challenges. One significant challenge was striking the right balance between technical depth and accessibility. It was important to ensure that developers with varying skill levels could follow the tutorial while still providing valuable insights for more experienced developers. Staying updated with the latest technologies and best practices in full stack development was another challenge. It was crucial to maintain the accuracy and relevance of the content, especially in an ever-evolving field like web development. Lastly, addressing potential platform-specific issues and discrepancies that readers might encounter during implementation was a concern. I had to anticipate and provide solutions for problems that could arise on different platforms or environments, making the tutorial more comprehensive and user-friendly.',
        slideLink: '',
        linklist: {
            'Demo': 'https://projects.elenadiaz.space/contactform_prod/index.html',
            'Github Frontend': 'https://github.com/diazelena325/contactform',
            'Github Backend': 'https://github.com/diazelena325/contactform_backend',
            'Tutorial dev.to ':
                'https://dev.to/elenadiaz32505/part-1-full-stack-contact-form-getting-started-react-frontend-2023-1bh4',
        },

        vid: '',
        cstyle: 'y',
        colors: ['#6d6875', '#b5838d', '#e5989b', '#ffb4a2', '#ffcdb2'],
    },
    {
        id: '14',
        type: 'react',
        img: squareapi,
        text: 'Square Payment API',
        techstack: ['React', 'Node', 'Express', 'REST API', 'Square SDK'],
        subtext:
            'Seamless payment processing in React applications with Square Payments SDK',
        desc: 'This project involved successful utilization of the React Square Web Payments SDK to integrate and test the Square Web Payments API within a React application. Additionally, I developed a dedicated backend API endpoint in Node.js to manage keys and handle payment processing. Notably, this integration was tailored for the development sandbox environment, intended solely for testing purposes.',
        achievements:
            "During this project, I had an enriching experience as I delved into learning about the Square Payment SDK, particularly focusing on implementing credit card and ACH functionality. It was truly enjoyable to work on both creating and implementing the backend, observing how it smoothly interacts with the frontend application. This newfound knowledge is undoubtedly going to be invaluable for my future projects, and I'm excited about the prospects of incorporating it into an e-commerce site.",
        challenges:
            'I encountered some deployment challenges while attempting to combine React, Next.js, and Node.js into a single application. To tackle this, I opted for a fresh approach, separating the frontend with a pure React setup and the backend with Node.js. While progressing, I faced additional hurdles while trying to connect to the backend due to SSL-related problems. Nevertheless, with determination and thorough research, I managed to overcome these obstacles by configuring the web host and adding the necessary HTTPS server code. As a result, I achieved a secure backend connection using HTTPS, making the project more securely.',
        slideLink: '',

        linklist: {
            'Demo': 'https://projects.elenadiaz.space/sqrcfe_prod/index.html',
        },

        vid: '',
        cstyle: 'y',
        colors: ['#0e0e0e', '#bebebe', '#1a1f71', '#0b72fa'],
    },
    {
        id: '13',
        type: 'react',
        img: hackernews,
        text: 'Hacker News List',
        techstack:
            ['React', 'Typescript', 'REST API', 'Styled Components', 'JEST', 'MaterialUI'],
        subtext: 'Displays the top 500 stories from Hacker News',
        desc: 'This project utilizes the Hacker News API to fetch and display the top 500 stories. Developed with React, TypeScript, Material UI icons, and styled components, this project served as a platform to deepen my understanding of TypeScript and explore Jest for testing. The application features a loader that displays before loading the initial 30 stories. To enhance user experience, infinite scrolling was implemented, allowing the next set of 30 news stories to load seamlessly. Each story is presented using a glassmorphism card style, providing an attractive and modern appearance. When a story contains a URL, the card indents slightly and exhibits an animation upon hovering, simulating a glass-like effect. Clicking on such a story opens the URL in a new tab. For stories without a URL, the card retains the same information but is presented with a recessed effect.',
        achievements:
            'A significant point of pride for me was overcoming the challenge of API calls and incorporating glassmorphism animation styling. This project exemplifies my dedication to mastering TypeScript and my ability to implement cutting-edge design features while handling challenging API interactions and testing intricacies. During development, I focused on expanding test coverage and addressing edge cases using Jest. Additionally, I paid close attention to responsiveness and the infinite scrolling behavior on smaller screens to ensure flawless loading of the next set of stories.',
        challenges:
            'While working on the project, I encountered difficulty in setting the appropriate types for the data retrieved from the API call and mapping it to the Newstory component in Listings.tsx. Consequently, I initially resorted to using the any type to ensure successful loading of the stories. After learning more about typescript, I made the change to an array type with the specified fields. When loading the next stories, there was a premature termination that did not display all the stories due to a 0.5 difference in smaller screen sizes. To fix this, I made use of "math.trunc" in the "useInfiniteScroll.tsx" file within the hooks folder.',
        slideLink: '',
        linklist: {
            'Demo': 'https://hacker-news-ediaz.netlify.app/',
            'Github': 'https://github.com/diazelena325/hacker_news_list',
        },
        vid: '',
        cstyle: 'y',
        colors: [
            '#02345A',
            '#008793',
            '#00bf72',
            '#ffffff2d',
            '#47474743',
            '#ffffff',
            '#cacacada',
        ],
    },
    {
        id: '12',
        type: 'react',
        img: shoplist,
        text: 'Shopping List',
        techstack: ['Javascript', 'HTML', 'CSS', 'Firebase'],
        subtext: 'Progressive Web App (PWA)',
        desc: 'Shopping List is a progressive web app designed to help users easily add and remove items needed for shopping. Built using HTML, CSS, JavaScript, and a Firebase database, this PWA is optimized for mobile use, providing a seamless experience for input and instant updates.',
        achievements:
            "Throughout the development of this project, I acquired valuable skills in utilizing Firebase for real-time data management, converting objects into arrays for efficient handling of data, and implementing a web application manifest to enhance the app's native-like appearance on mobile devices.",
        challenges:
            'The main challenge I faced was achieving responsive design to ensure the app looks aesthetically pleasing and functions well on various screen sizes. To overcome this, I delved into CSS @media screens to enhance responsiveness and optimize the user experience.',
        slideLink: '',
        linklist: {
            'Demo': 'https://projects.elenadiaz.space/shopping_list/index.html',
        },
        vid: '',
        cstyle: 'y',
        colors: ['#033540', '#015366', '#247BA0', '#408E91', '#f1f1f6', '#DCE1EB'],
    },
    {
        id: '11',
        type: 'react',
        img: movielist,
        text: 'Movie List',
        techstack: ['Javascript', 'HTML', 'CSS', 'Firebase'],
        subtext: 'PWA utilizing reusable components from Shopping List App',
        desc: 'Movie List is a progressive web app developed using components in the Shopping List app. This app enables users to easily add and remove movies they want to watch. Built using HTML, CSS, JavaScript, and a Firebase database, this PWA is optimized for mobile use, offering seamless input and instant updates.',
        achievements:
            'In the process of developing this project, I leveraged the reusable components from the "Shopping List" project, which accelerated the development process. I also gained valuable experience in implementing Firebase for real-time data management, converting objects into arrays for efficient data handling, and adding a web application manifest to achieve a native app-like experience on mobile devices.',
        challenges:
            "The primary challenge I encountered was achieving responsive design to ensure the app maintains an appealing appearance and functionality across various screen sizes. Through the use of CSS @media screens, I enhanced the app's responsiveness, gaining insights into creating a more optimized user experience. Additionally, I look forward to adding more functionality to the app in future iterations.",
        slideLink: '',

        linklist: {
            'Demo': 'https://projects.elenadiaz.space/movie_list/index.html',
        },

        vid: '',
        cstyle: 'y',
        colors: [
            '#383A56',
            '#232931',
            '#335164',
            '#347e97',
            '#24afc5',
            '#12e2eb',
            '#1ac592',
            '#C6DE41',
            '#EEEEEE',
        ],
    },
    {
        id: '10',
        type: 'react',
        img: elenafacts,
        text: 'Elena Facts',
        techstack: ['React', 'JavaScript', 'HTML', 'CSS'],
        subtext: 'Dark / Light Mode Switch',
        desc: 'Elena Facts is a webpage featuring a dynamic dark and light mode switch, implemented using React props and useState. Users can seamlessly toggle between dark and light themes for an enhanced viewing experience.',
        achievements:
            "Throughout the development of this project, I deepened my understanding of React props and useState, which allowed me to create an interactive switch. The innovative feature of changing the star's color by modifying the icon and theme colors added a delightful touch to the user interface.",
        challenges:
            'While building this project, I encountered the challenge of refactoring and breaking down components to enhance reusability. In future projects, I plan to implement this switch more efficiently by incorporating reusable components, making it easier to integrate this feature across different applications.',
        slideLink: '',

        linklist: {
            Demo: 'https://projects.elenadiaz.space/elenafacts_prod/index.html',
            Github: 'https://github.com/diazelena325/react-facts-static',
        },

        vid: '',
        cstyle: 'y',
        colors: ['#000000', '#21222A', '#282D35', '#FFE500', '#F636A9', '#FFFFFF'],
    },
    {
        id: '9',
        type: 'ux',
        img: homeRental,
        text: 'Home Rental',
        techstack: ['Figma', 'React', 'JavaScript', 'HTML', 'CSS'],
        subtext: 'UX Design to Working Prototype',
        desc: 'Home Rental is a comprehensive UX design case study that culminated in the development of a website, catering to both mobile and desktop users. This design needed to ensure a seamless and efficient experience facilitating the search for rental homes.',
        achievements:
            'Serving as the Lead UX designer, researcher, and developer for this project, I successfully conducted extensive user research to identify user preferences and pain points. The design process involved creating wireframes, prototypes, and a sitemap, ultimately resulting in the development of a functional prototype in React.',
        challenges:
            "Throughout the project, I faced the challenge of maintaining consistent styling and functionality across both mobile and desktop platforms. Additionally, the main focus was on resolving issues related to the rental search process, and highlighting the user's significant criteria effectively to enhance decision-making.",
        slideLink: '',

        linklist: {
            Demo: 'https://rental-homes.netlify.app/',
            Github: 'https://github.com/diazelena325/rental_homes',
            'Figma High Fidelity Prototype':
                'https://www.figma.com/proto/ZJ6PCrveaWMOQcjnVSGLP4/High-Fidelity%3A-Rental-Homes?page-id=0%3A1&node-id=4-46&starting-point-node-id=4%3A46&mode=design&t=3MY31uGKQylbhQKM-1',
        },

        vid: '',
        cstyle: 'y',
        colors: ['#373F41', '#545454', '#3C64B1', '#FFE603', '#FFFFFF'],
    },
    {
        id: '8',
        type: 'react',
        img: ecommerce,
        text: 'IroIro Prints Shop',
        techstack: ['React', 'JavaScript', 'HTML', 'CSS', 'Styled Components'],
        subtext: 'E-commerce Website',
        desc: 'IroIro Prints Shop is a sample E-commerce website built using React and styled components, offering a responsive design to accommodate various devices. The homepage features a slider, and the website incorporates different components to showcase the available items for purchase.',
        achievements:
            'Throughout the development of IroIro Prints Shop, I successfully achieved responsiveness, ensuring that the website provides an optimal viewing experience across different devices. Additionally, working on this project allowed me to practice and enhance my skills in React development.',
        challenges:
            'While the website already showcases many features, I am actively working on further enhancing its functionality to create a seamless and fully functional E-commerce experience for users. Continuously refining and fine-tuning the project is an ongoing endeavor to deliver the best possible outcome.',
        slideLink: '',

        linklist: {
            Demo: 'https://shop.elenadiaz.space/',
        },

        vid: '',
        cstyle: 'y',
        colors: ['#365B6D', '#008080', '#9ADEFF', '#d3f4ff', '#fcdada', '#fbfbfb'],
    },
    {
        id: '7',
        type: 'react',
        img: ron,
        text: 'Ron Swanson Quotes',
        techstack: ['React', 'JavaScript', 'HTML', 'CSS', 'REST API', 'HTML2Canvas'],
        subtext:
            "Quote Generator by Ron Swanson from TV Show 'Parks and Recreation' ",
        desc: "Ron Swanson Quote Generator is your source for random yet profound wisdom from the iconic character Ron Swanson in the TV show 'Parks and Recreation'. Harnessing the power of React, HTML2Canvas, and the Ron Swanson Quotes API by James Wright, this web application delivers entertaining and inspiring quotes with a simple click. You can even immortalize your favorite quotes as images for future motivation. The current focus is on enhancing user customization, allowing you to choose the image size and customize the background and Ron's image to make your experience even more personalized.",
        achievements:
            'This project brought me immense joy as it successfully breathed life into the wit and humor of Ron Swanson through a user-friendly web application. I was thrilled to leverage React and external APIs, crafting a seamless and delightful experience for fellow fans of the show. The incorporation of HTML2Canvas to save quotes as images added an exciting layer of utility and fun to the generator.',
        challenges:
            "While developing the Ron Swanson Quote Generator, one notable challenge was refining the image-saving functionality to allow users to select image sizes. Additionally, the effort to make the background and Ron's image dynamic posed technical challenges.",
        slideLink: '',
        linklist: {
            Demo: 'https://projects.elenadiaz.space/ronswanson_prod/index.html',
            Github: 'https://github.com/diazelena325/ronswanson_qg',
        },
        vid: '',
        cstyle: 'y',
        colors: ['#F7B32B', '#F70C95', '#87B5C4', '#000000', '#6F6D6E'],
    },
    {
        id: '6',
        type: 'react',
        img: slider,
        text: 'Photo Carousel',
        techstack: ['JavaScript', 'HTML', 'CSS'],
        subtext: 'Image Slider',
        desc: 'Carousel component in HTML, CSS, and Javascript',
        achievements: '',
        challenges: '',
        slideLink: '',
        linklist: {
            Demo: 'https://projects.elenadiaz.space/slider/index.html',
        },
        vid: '',
        cstyle: 'y',
        colors: ['#2C3A56', '#CE7777', '#E9C4C4', '#F2E5E5'],
    },
    {
        id: '5',
        type: 'ux',
        img: restaurant,
        text: 'Miyabi Yakitori',
        techstack: ['Figma'],
        subtext: 'UX Design Case Study',
        desc: 'Design a mobile-web app for a restaurant in Osaka',
        achievements: '',
        challenges: '',
        slideLink:
            'https://docs.google.com/presentation/d/1xaRtinrQjHtQVwAKa5GjouwM39M9avC-LUNV7qgrbeQ/edit?usp=sharing',

        linklist: {
            'Figma High Fidelity Prototype':
                'https://www.figma.com/proto/JVILJBTmJ731RimlgjAsBE/Google-Portfolio-1-Restaurant?page-id=117%3A184&type=design&node-id=117-185&viewport=710%2C423%2C0.34&t=854jeccePN7nLT9h-1&scaling=scale-down&starting-point-node-id=117%3A185&show-proto-sidebar=1&mode=design',
        },

        vid: '',
        cstyle: 'y',
        colors: ['#3F4142', '#6F7676', '#7B8C94', '#D06C45', '#D5C7B6'],
    },
    {
        id: '4',
        type: 'ux',
        img: healthyMeals,
        text: 'Healthy Meals',
        techstack: ['Figma'],
        subtext: 'UX Design Case Study',
        desc: 'In this project, I designed a user experience for a mobile app and website aimed at helping adults learn how to cook healthier meals. The goal was to create an intuitive and engaging platform that would empower users to find enjoyable meals while taking control of their health and potentially reducing ailments caused by malnutrition.',
        achievements:
            'Throughout this project, I gained valuable experience in the User Experience (UX) process, which involved conducting user and product research, creating wireframes, and prototyping in Figma. By following these steps, I ensured that the final design met the needs and expectations of the target audience.',
        challenges:
            'One of the main challenges I encountered was ensuring that the designs prioritized the information the user needed in an efficient and effective manner. This required careful consideration of the user flow and information architecture to create a seamless and intuitive experience. Additionally, I made sure to include accessibility features in all functionality and UI layout to ensure an inclusive user experience.',
        slideLink:
            'https://docs.google.com/presentation/d/1u4HYntNSACVwK_LwioiCtPS4CJ1rC1QXKPmmQx0teEY/edit?usp=sharing',

        linklist: {
            'Figma High Fidelity Prototype':
                'https://www.figma.com/proto/qNi3fYOGGMbHFaQkobjDbQ/Social-Good-Project?page-id=101%3A2&node-id=101%3A3&viewport=-906%2C367%2C1.01&scaling=scale-down&starting-point-node-id=101%3A3',
        },
        vid: '',
        cstyle: 'y',
        colors: ['#35495E', '#347474', '#42B883', '#FF7E67', '#D9D9D9'],
    },

    {
        id: '1',
        type: 'react',
        img: mySite,
        text: 'My Portfolio',
        techstack:
            ['React', 'JavaScript', 'Node', 'MaterialUI', 'Styled Components', 'Figma', 'Procreate'],
        subtext: 'Responsive single page website with reusable components',
        desc: "My portfolio is a responsive single-page website developed using React, styled components, and Material UI. I personally designed the graphics using Procreate and Figma.  It includes embedded Google Slides for effective UX project display and utilizes reusable components. I've also built a backend for contact form management, enabling message reception and automated responses.",
        achievements:
            "One of the most satisfying aspects of my portfolio website is its continuous evolution. With each new version, I've managed to enhance its functionality and design. I take pride in the progress I've made in frontend development, particularly in making components more reusable by leveraging React hooks like props and states. The website's growth mirrors my own development as a developer, and I'm excited to continue refining it.",
        challenges:
            'One of the notable challenges I faced was implementing the contact form. Initially, I hesitated to delve into backend functionality, opting to use a third-party service to manage messaging. However, this approach proved problematic and eventually stopped working. As I grew more proficient in development and troubleshooting, I took on the challenge of building the backend for the contact form, ultimately achieving success without depending on external services.',
        slideLink: '',
        linklist: {},
        vid: '',
        cstyle: 'y',
        colors: [
            '#2F2F2F',
            '#57737A',
            '#bed2d5',
            '#bca446',
            '#FDECDF',
            '#f7b681',
            '#efd5d5',
        ],
    },
];
