import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';

import Main from "./pages/MainPage";
import Homepage from "./pages/Home";
import About from "./pages/AboutSite";
import Error from "./pages/Error";
import Contact from "./pages/Contact";
import Projects from "./pages/Projects";
import ProjectDetail from "./pages/ProjectDetail";
import ReactGA from 'react-ga4';


ReactGA.initialize('G-ZX1M1XN4DL');


const App = () => {

  return (
    <BrowserRouter basename="/">
      <Routes>
        <Route path="" element={<Main />}>
          <Route index element={<Homepage />} />
          <Route path="projects" element={<Projects />} />
          <Route
            path='projects/:id'
            element={<ProjectDetail />}

          />
          <Route path="about" element={<About />} />
          <Route path="contact" element={<Contact />} />
          <Route path="*" element={<Error />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
