import React from 'react'
import data from '../../data.json';


function Skills() {
    return (
        <article className='about-info'>
            <div
                className='sk-info fade blur-container'
            >

                {data.skills.map((item, i) => (
                    <div key={i}>
                        <h2 className=' fs-400 ff-serif text-accent-4 bold'>
                            {item.category}
                        </h2>

                        {item.sk.map((k, i) => (
                            <p key={i} className='text-dark ff-sans-normal fs-300 bold'>
                                {k}
                            </p>
                        ))}



                    </div>
                ))
                }


            </div>
        </article>
    )
}

export default Skills