import React from 'react'
import data from '../../data.json';

function Hello() {
    const tab = data.destinations[0];

    return (
        <>
            {/* {data.destinations.map((tab, i) => ( */}
            <article
                className='about-info fade blur-container'
            >

                <div >
                    {tab.images.drawing ? <img className='about-info-img'
                        src={require(`../../images/${tab.images.drawing}`)}
                        alt={tab.title}
                    /> : <></>}
                    <h2 className='d-block fs-500 ff-serif text-accent-4'>
                        {tab.intro}
                    </h2>
                    <br />
                    <p className='text-dark ff-sans-normal fs-400'>
                        {tab.description}
                    </p>
                    {tab.images.likes ? <img className='about-info-img'
                        src={require(`../../images/${tab.images.likes}`)}
                        alt={tab.title}
                    /> : <></>}
                    <p className='text-dark ff-sans-normal fs-400'>
                        {tab.midtro}
                    </p>
                    <br />
                    <div className='about-meta flex'><p className='text-dark ff-sans-normal fs-450 bold'>
                        {tab.outro}
                    </p></div>
                </div>

            </article>
            {/* ))} */}
        </>
    )
}

export default Hello