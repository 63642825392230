import React from 'react';
import '../styles/main.css';
import { Outlet } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Logo_light from '../images/shared/logo-light.png';
import Footer from '../components/Footer';
import Navigation from '../components/Navigation';



const MainPage = () => {
	const [page, setPage] = React.useState('');

	const handlePageClick = (e) => {
		setPage(e.target.id);
	};

	return (
		<div className=' AppBackground'>
			<a className="skip-to-content" href="#main">
				Skip to content
			</a>
			{/*<!-- navigation -->*/}
			<header className='primary-header flex'>
				<div>
					<Link to='/'>
						<img
							src={Logo_light}
							alt='Elena Diaz logo'
							className='logo'
						/>
					</Link>
				</div>

				<Navigation page={page} handlePageClick={handlePageClick} />
			</header>
			<Outlet id="main" />

			<Footer />
		</div>
	);
};

export default MainPage;
