import React, { useEffect } from 'react'
import '../styles/projectdetail.css';
import { NavLink, useParams } from "react-router-dom";
import { popularProducts } from '../projectData.js';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import YardIcon from '@mui/icons-material/Yard';
import ReactGA from 'react-ga4';

function ProjectDetail() {
    const { id } = useParams();

    const thisProduct = popularProducts.find((prod) => prod.id === id);

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: `Project Detail:${thisProduct?.text}-${id}` });
    });

    const pType = thisProduct.type;
    const cstyle = thisProduct.cstyle;
    const mcolor = thisProduct.colors;
    const achieve = thisProduct.achievements;
    const chall = thisProduct.challenges;
    const links = thisProduct.linklist;
    const techstack = thisProduct.techstack;
    const desc = thisProduct.desc;
    const extraimgs = thisProduct.extra_imgs;

    useEffect(() => {
        window.scrollTo(0, 0)
    })



    return (
        <div className='dt-div flex fade'>
            <NavLink to="/projects" className="flex f-center-horizontal navlink">
                <ArrowBackIcon className="BackIconSizeContact" />
                <h3 className='fs-450 ff-serif'>All Projects</h3>
            </NavLink>
            <div>
                <h2 className='d-block numbered-title text-dark'>
                    <span aria-hidden='true'>/</span>{thisProduct?.text}
                </h2>

                <span className=' text-accent-4 ff-serif fs-450 blur-container'>
                    {thisProduct?.subtext}
                </span>
            </div>

            <div className='flex f-center-vertical '>
                <img className='dt-img' src={thisProduct?.img} alt={thisProduct?.subtext} />

                <div className='flex dt-content blur-container f-center-vertical'>
                    {Object.keys(links).length > 0 ? (
                        <div>
                            <h2 className='d-block fs-500 ff-serif text-accent-5 letter-spacing-3'>Links</h2>
                            <div className='dt-links'> {Object.keys(links).map((key, i) => (
                                <a
                                    key={i}
                                    href={links[key]}
                                    target='_blank'
                                    rel='noreferrer'
                                    className='DemoLinks'
                                >
                                    {key}
                                </a>
                            ))}</div>

                        </div>
                    ) : (
                        <></>
                    )}

                    <h2 className='d-block fs-500 ff-serif text-accent-5 letter-spacing-3'>Tech Stack</h2>
                    <div className='pallete flex f-center-horizontal'>
                        {techstack.map((skill, i) => (
                            <button key={i} className='dt-button fs-400 '>{skill}</button>
                        ))}
                    </div>
                    <div>
                        <h2 className='d-block fs-500 ff-serif text-accent-5 letter-spacing-3'>Description</h2>
                        {pType === 'ux' ? <p>UX Design prompt: {desc} </p> : <p>{desc}</p>}
                    </div>


                    {achieve !== '' ? (
                        <div>
                            <h2 className='d-block fs-500 ff-serif text-accent-5 letter-spacing-3'>Key Achievements</h2>
                            <p>{achieve}</p>
                        </div>
                    ) : (
                        <></>
                    )}
                    {chall !== '' ? (
                        <div>
                            <h2 className='d-block fs-500 ff-serif text-accent-5 letter-spacing-3'>Challenges Faced</h2>
                            <p>{chall}</p>
                        </div>
                    ) : (
                        <></>
                    )}
                    <h2 className='d-block fs-500 ff-serif text-accent-5 letter-spacing-3'>Color Pallete</h2>
                    {cstyle !== '' ? (
                        <div className='pallete flex f-center-horizontal'>
                            {' '}
                            {mcolor.map((mcolor, i) => (
                                <div key={i}>

                                    <YardIcon sx={{ color: mcolor, fontSize: '4rem' }} />

                                </div>

                            ))}
                        </div>
                    ) : (
                        <></>
                    )}

                    {extraimgs &&
                        extraimgs.map((img, i) => (
                            <img key={i} className='dt-img' src={img} alt={thisProduct?.subtext} />
                        ))

                    }
                </div>

            </div>
        </div>
    )
}

export default ProjectDetail